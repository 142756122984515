@media print {
    /* @page {
        size: 80mm auto; /* Set the width to 80mm */
    /* margin: 0; */
    /* } */

    /* body, .payReceipt {
        width: 80mm;
        margin: 0;
        padding: 0;
        font-size: 8px;
        font-weight: normal;
    } */

    .receiptTable,
    .receiptTable td,
    .receiptTable th {
        border: 1px solid black;
        border-collapse: collapse;
        padding: 5px;
    }

    .receiptTable td,
    .receiptTable th {
        text-align: left;
        /* Left-align for better readability */
    }

    .studentReceiptHeader h2,
    .studentPayDetails h2,
    .studentReceiptBarcodeSection h2 {
        font-size: 9px;
        /* Adjust font size for clarity */
        font-weight: normal;
        margin: 0;
    }

    .studentReceiptBarcodeSection {
        text-align: center;
    }

    .studentReceiptBarCode {
        width: 800px;
        /* Set barcode size appropriate for scanning */
        height: auto;
    }

    /* @page {
        size: 80mm auto;
    }

    .payReceipt {
        margin: 20mm 5mm;
        font-size: 10px;
        font-weight: normal;
    }

    .receiptTable {
        border: 1px solid black;
        border-collapse: collapse;
    }

    .receiptTable tr,
    .receiptTable td {
        border: 1px solid black;
        color: black !important;
    }

    .thead-light th {
        border: 1px solid black !important;
        color: black !important;
        font-weight: bold !important;
    }
    
    .emptyRow {
        border: none !important;
        height: 10px;
    } */

    /* .receiptTable td {
        border-collapse: collapse;
        border: 1px solid black !important;
    } */

    .table tbody+tbody {
        border: 1px solid black !important;
    }

    /* Student Receipt */
    .studentReceiptHeader {
        text-align: center;
        border: 1px black solid;
        padding: 5px;
    }

    .studentReceiptHeader h2 {
        font-size: 54px;
    }

    .studentPayDetails {
        margin-left: 10px;
        margin-top: 40px;
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 2px black solid;
    }

    .studentPayDetails h2 {
        font-size: 40px;
    }

    .studentReceiptBarcodeSection,
    .studentReceiptInstSection {
        text-align: center;
    }

    /* .studentReceiptBarcodeSection h2 {
        font-size: 46px;
    } */

    /* .studentReceiptBarCode {
        width: 800px;
        height: auto;
    } */

    .studentReceiptInstSection h2 {
        font-size: 50px;
    }

    .receiptTitle1 {
        font-size: 20px;
    }
    .studentBulkPayDetails {
        margin-left: 10px;
        border-bottom: 2px black solid;
    }
    .studentBulkPayHeader {
        margin-bottom: 40px;
    }
}


.receiptTable td {
    border-collapse: collapse;
    border: 1px solid black !important;
}

.receiptTable th,
.receiptTable tr,
.receiptTable td {
    text-align: center;
}

.receiptTotal {
    text-align: right !important;
}

.receiptTitle {
    text-align: center;
}

.receiptTitleTA {
    text-align: center;
    font-size: 30px;
}

.receiptTitle2 {
    text-align: center;
    font-size: 30px;
}

.receiptTableBody {
    margin-bottom: 20px;
}

.totalACell td {
    border-right: none !important;
}

.emptyRow {
    border: none !important;
    height: 10px;
}

.receiptTitle1 {
    font-size: 35px;
    text-align: center;
}