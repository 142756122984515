@media screen and (min-width: 768px) {
    .assistant-main {
        margin-left: 150px !important;
    }
    .assistant-nav {
        max-width: 150px !important;
    }
    .main-content .assistant-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .assistantNewStudentRight {
        text-align: right;
    }
    .assistantStudentImage {
        width: 220px;
        height: auto;
        margin: 0 auto;
    }
    .assistantStudentHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .assistantStudentButtonSection {
        display: flex;
        flex-direction: column;
        width: 220px;
        text-align: right;
        gap: 5px;
        margin: 0 auto;
    }
}
@media screen and (min-width: 993px) {
    .imageSection {
        display: grid;
        justify-content: end;
        gap: 5px;
    }
    .assistantStuImage {
        justify-content: flex-end;
    }
}
@media screen and (max-width: 992px) {
    .imageSection {
        text-align: center;
    }
    .studentInsertUpper {
        display: flex;
        flex-direction: column-reverse;
    }
    .assistantStudentImage {
        width: 260px;
        height: auto;
        text-align: center;
    }
    .assistantStudentButtonSection {
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 260px;
        gap: 5px;
        margin: 0 auto;
        margin-bottom: 10px;
    }
    .assistantStuImage {
        justify-content: center;
    }
}

@media screen and (max-width: 576px) {
    .imageSection {
        display: grid;
        justify-content: center;
        gap: 5px;
    }
    .assistantStudentImage {
        width: 280px;
        height: auto;
    }
    .assistantStudentButtonSection {
        display: flex;
        flex-direction: column;
        width: 280px;
        text-align: right;
        gap: 5px;
        margin-bottom: 10px;
    }
}
.lateChit, .inactivate {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.assistantStuImage {
    display: flex;
}